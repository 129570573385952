var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Item',{attrs:{"item":_vm.item,"parent":_vm.parent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var textAlign = ref.textAlign;
return [_c('div',{staticClass:"w-full"},[(_vm.headlineItem || _vm.introItem)?_c('div',{class:[
        {
          'lg:w-2/3': !_vm.isHalf,
          'mr-auto': textAlign === 'text-left',
          'mx-auto': textAlign === 'text-center',
          'ml-auto': textAlign === 'text-right',
        } ]},[(_vm.headlineItem)?_c('Headline',{class:_vm.introItem ? 'mb-4' : 'mb-8',attrs:{"item":_vm.headlineItem,"parent":_vm.item}}):_vm._e(),_vm._v(" "),(_vm.introItem)?_c('Intro',{staticClass:"mb-12",attrs:{"item":_vm.introItem,"parent":_vm.item}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c(_vm.isAdminMode ? 'SortableList' : 'div',_vm._b({tag:"Component",staticClass:"grid gap-8 md:gap-12 text-left",class:[
        _vm.gridClass,
        {
          'mr-auto ': textAlign === 'text-left',
          'mx-auto': textAlign === 'text-center',
          'ml-auto': textAlign === 'text-right',
        } ],model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},'Component',_vm.sortableListBindings,false),_vm._l((_vm.items),function(subItem,idx){return _c(_vm.isAdminMode ? 'SortableItem' : 'div',{key:subItem.uid,tag:"Component",attrs:{"index":idx}},[_c('Quote',{staticClass:"h-full",attrs:{"item":subItem,"parent":_vm.item}})],1)}),1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }