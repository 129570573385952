<template>
  <Item v-slot="{ textAlign }" :item="item" :parent="parent">
    <div class="w-full">
      <div
        v-if="headlineItem || introItem"
        :class="[
          {
            'lg:w-2/3': !isHalf,
            'mr-auto': textAlign === 'text-left',
            'mx-auto': textAlign === 'text-center',
            'ml-auto': textAlign === 'text-right',
          },
        ]"
      >
        <Headline
          v-if="headlineItem"
          :item="headlineItem"
          :parent="item"
          :class="introItem ? 'mb-4' : 'mb-8'"
        />

        <Intro
          v-if="introItem"
          :item="introItem"
          :parent="item"
          class="mb-12"
        />
      </div>

      <Component
        :is="isAdminMode ? 'SortableList' : 'div'"
        v-model="items"
        v-bind="sortableListBindings"
        class="grid gap-8 md:gap-12 text-left"
        :class="[
          gridClass,
          {
            'mr-auto ': textAlign === 'text-left',
            'mx-auto': textAlign === 'text-center',
            'ml-auto': textAlign === 'text-right',
          },
        ]"
      >
        <Component
          :is="isAdminMode ? 'SortableItem' : 'div'"
          v-for="(subItem, idx) in items"
          :key="subItem.uid"
          :index="idx"
        >
          <Quote :item="subItem" :parent="item" class="h-full" />
        </Component>
      </Component>
    </div>
  </Item>
</template>

<script>
import { dispatch, get } from 'vuex-pathify'
import blockMixin from '@/mixins/block'
import itemMixin from '@/mixins/item'

export default {
  name: 'Quotes',

  components: {
    SortableList: () => import('@/components/SortableList'),
    SortableItem: () => import('@/components/SortableItem'),
    Headline: () => import('@/components/Items/Headline'),
    Intro: () => import('@/components/Items/Intro'),
    Quote: () => import('@/components/Items/Quote'),
    Item: () => import('@/components/Item'),
  },

  mixins: [blockMixin, itemMixin],

  computed: {
    isAdminMode: get('isAdminMode'),

    headlineItem() {
      return this.item.items.find(item => item.name === 'Headline')
    },

    introItem() {
      return this.item.items.find(item => item.name === 'BodyText')
    },

    sortableListBindings() {
      if (!this.isAdminMode) {
        return
      }

      return {
        'use-drag-handle': true,
        axis: 'xy',
        appendTo: `[data-uid="${this.item.uid}"]`,
      }
    },

    gridClass() {
      if (this.isHalf) {
        return null
      }

      if (this.numItems === 1) {
        return 'lg:grid-cols-1 lg:w-1/2'
      } else if (this.numItems === 3) {
        return 'lg:grid-cols-3'
      }
      return 'lg:grid-cols-2'
    },

    numItems() {
      return this.items.length
    },

    items: {
      get() {
        return this.item.items.filter(item => item.name === 'Quote')
      },

      set(val) {
        dispatch('page/update', {
          mutation: 'SET_BLOCK_ITEMS',
          item: { uid: this.item.uid }, // parent
          value: val,
        })
      },
    },
  },
}
</script>

<style></style>
